<template>
  <b-overlay :show="loading">
    <section>
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col md="12">
            <b-card v-for="(item, ind) in dataSoal" :key="ind">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="name">Judul</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Judul"
                      >
                        <b-form-input
                          id="name"
                          v-model="item.name"
                          placeholder="Masukkan judul soal"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <label for="Pilih Paket">Pilih Kategori Paket</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Kategori Paket"
                      >
                        <v-select
                          v-model="item.paket_id"
                          label="text"
                          :options="optpaket_id"
                          :reduce="(option) => option.value"
                          placeholder="- Pilih -"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group>
                      <label for="Bobot Soal">Bobot Soal</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Bobot Soal"
                      >
                        <b-form-input
                          id="bobot"
                          v-model="item.bobot"
                          placeholder="Masukkan bobot soal"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="Tipe Soal">Tipe Soal</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Bobot Soal"
                      >
                        <v-select
                          v-model="type"
                          label="label"
                          :options="typesSoal"
                          :reduce="(option) => option.value"
                          placeholder="- Pilih -"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="type == 'benar_salah'">
                    <b-form-group>
                      <label for="Jumlah Opsi Soal">Jumlah Opsi Soal</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Jumlah Opsi"
                      >
                        <v-select
                          v-model="selectedOption"
                          label="name"
                          :options="sum_options"
                          :reduce="(option) => option.id"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="type == 'pauli'">
                    <b-form-group label="Jumlah Opsi Soal" label-for="opsi">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Jumlah Opsi"
                      >
                        <b-form-input
                          v-model="selectedOption"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="3"
                    v-if="type == 'pg' || type == 'multiple_choice'"
                  >
                    <b-form-group>
                      <label for="Jumlah Opsi Jawaban"
                        >Jumlah Opsi Jawaban</label
                      >
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Jumlah Opsi"
                      >
                        <v-select
                          v-model="selectedOption"
                          label="name"
                          :options="sum_options"
                          :reduce="(option) => option.id"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="type == 'pg'">
                    <b-form-group>
                      <label for="Kunci Jawaban">Kunci Jawaban</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Kunci Jawaban"
                      >
                        <v-select
                          v-model="is_correct"
                          label="label"
                          :options="masterOptions"
                          :reduce="(option) => option.value"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="2" v-if="type == 'multiple_choice'">
                  <b-form-group label="Kunci Jawaban" label-for="opsi">
                    <v-select multiple v-model="is_correct" label="label" :options="masterOptions"
                      :reduce="option => option.value" />
                  </b-form-group>
                </b-col> -->
                  <!-- <b-col md="2">
                          <b-form-group label="opsi" label-for="opsi">
                              <v-select
              v-model="opsi"
              label="label"
              :options="opsisSoal"
              :reduce="option => option.value"
            />
                          </b-form-group>
                        </b-col> -->
                </b-row>
              </b-form>
              <b-tabs v-if="type != 'pauli'">
                <!-- Pertanyaan -->
                <b-tab active>
                  <!-- title -->
                  <template #title>
                    <!-- <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" /> -->
                    <span class="font-weight-bold">Pertanyaan </span>
                    <!-- <label class="font-weight-bold" for="Pertanyaan">Pertanyaan</label> -->
                    <small class="text-danger"> <b> (*)</b></small>
                  </template>
                  <b-form>
                    <b-row>
                      <b-col>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Pertanyaan"
                        >
                          <!-- <b-form-group label="Konten" label-for="Konten"> -->
                          <quill-editor
                            id="Konten"
                            v-model="item.content"
                            :options="editor_options"
                            :state="errors.length > 0 ? false : null"
                          >
                          </quill-editor>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label class="font-weight-bold mt-2"
                          >Upload file untuk Pertanyaan dengan format: jpg, png,
                          pdf, doc, mp3, mp4
                        </label>
                        <small class="text-info"> <b> (opsional)</b></small>
                        <b-form-file
                          id="Thumbnail"
                          v-model="item.file"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          no-drop
                          accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                        />
                        <!-- <div v-if="item.file != null" class="flex justify-center">
                          <b-img :src="item.file" alt="Lampiran"  />
                        </div> -->
                        <!-- <div class="mt-4 mb-4" v-if="item.file != null">
                        <b-alert variant="primary" class="flex justify-between mb-4">
                          <p class="mb-4 flex items-center">Lampiran Soal : <a href="#">{{ item.file.name }}</a>
                            <a href="#" @click.prevent="unhandleFile(ind, $event)" class="ml-4">
                              <feather-icon icon="XIcon" svgClasses="sm text-danger"></feather-icon>
                            </a>
  
                          </p>
                        </b-alert>
                        <div v-if="item.preview_file != null" class="flex justify-center">
                          <b-img :src="item.preview_file" alt="Lampiran"  />
                        </div>
                      </div> -->
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
                <b-tab>
                  <template #title>
                    <!-- <feather-icon icon="HelpCircleIcon" size="18" class="mr-50" /> -->
                    <span class="font-weight-bold">Pembahasan </span>
                    <small class="text-danger"> <b> (*)</b></small>
                  </template>
                  <b-form>
                    <b-row>
                      <b-col>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Pembahasan"
                        >
                          <!-- <b-form-group label="Konten" label-for="Konten"> -->
                          <quill-editor
                            id="Pembahasan"
                            v-model="item.pembahasan"
                            :options="editor_options"
                            :state="errors.length > 0 ? false : null"
                          >
                          </quill-editor>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- </b-form-group> -->
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
              <hr />
              <div
                v-if="
                  type == 'pg' ||
                  type == 'multiple_choice' ||
                  type == 'benar_salah'
                "
              >
                <b-tabs class="mt-2">
                  <div v-for="(data, index) in item.dataOptions" :key="index">
                    <b-tab>
                      <template #title>
                        <!-- <feather-icon icon="BookOpenIcon" size="18" class="mr-50" /> -->
                        <div v-if="type != 'pauli'">
                          <label class="font-weight-bold" v-if="index + 1 == 1"
                            >Opsi. A
                            <small class="text-danger">
                              <b> (*)</b></small
                            ></label
                          >

                          <label class="font-weight-bold" v-if="index + 1 == 2"
                            >Opsi. B
                            <small class="text-danger">
                              <b> (*)</b></small
                            ></label
                          >

                          <label class="font-weight-bold" v-if="index + 1 == 3"
                            >Opsi. C
                            <small class="text-danger">
                              <b> (*)</b></small
                            ></label
                          >

                          <label class="font-weight-bold" v-if="index + 1 == 4"
                            >Opsi. D
                            <small class="text-danger">
                              <b> (*)</b></small
                            ></label
                          >

                          <label class="font-weight-bold" v-if="index + 1 == 5"
                            >Opsi. E
                            <small class="text-danger">
                              <b> (*)</b></small
                            ></label
                          >
                        </div>
                      </template>
                      <b-form>
                        <b-row>
                          <b-col>
                            <!-- <label class="font-weight-bold">Jawaban</label> -->

                            <quill-editor
                              id="Konten"
                              v-model="data.content"
                              :options="editor_options"
                            />
                            <!-- <div v-if="data.file != null" class="flex justify-center">
                          <b-img :src="data.file" alt="Lampiran"  />
                        </div> -->

                            <!-- <b-button variant="primary" size="small" icon-pack="feather" icon="icon-link"
                                @click.prevent="addAttachSoalOption('file-opsi-' + ind + '-' + index, $event)">   
                                Tambah Lampiran
                              </b-button>
  
                              <div class="mt-3" v-if="data.file != null">
                                <b-alert variant="primary" class="flex justify-between mb-4">
                                  <p class="mb-4 flex items-center">Lampiran Opsi : <a href="#">{{ data.file.name }}</a>
                                    <a href="#" @click.prevent="unhandleFileOption($event, { soal: ind, opsi: index })"
                                      class="ml-4">
                                      <feather-icon icon="XIcon" svgClasses="sm text-danger"></feather-icon>
                                    </a>
  
                                  </p>
                                </b-alert>
                                <div v-if="data.preview_file != null" class="flex justify-center">
                                  <b-img :src="data.preview_file" alt="Opsi Gambar"  />
                                </div>
                              </div> -->
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- </b-form-group> -->
                          <!-- <b-form-group label="Konten" label-for="Konten"> -->
                          <b-col
                            md="2"
                            v-if="
                              type == 'multiple_choice' || type == 'benar_salah'
                            "
                          >
                            <label
                              class="font-weight-bold mt-2"
                              v-if="index + 1 == 1"
                              >Jawaban Opsi. A</label
                            >
                            <label
                              class="font-weight-bold mt-2"
                              v-if="index + 1 == 2"
                              >Jawaban Opsi. B</label
                            >
                            <label
                              class="font-weight-bold mt-2"
                              v-if="index + 1 == 3"
                              >Jawaban Opsi. C</label
                            >
                            <label
                              class="font-weight-bold mt-2"
                              v-if="index + 1 == 4"
                              >Jawaban Opsi. D</label
                            >
                            <label
                              class="font-weight-bold mt-2"
                              v-if="index + 1 == 5"
                              >Jawaban Opsi. E</label
                            >
                            <b-form-checkbox
                              v-if="type == 'multiple_choice'"
                              v-model="data.is_correct"
                              value="1"
                              class="custom-control-primary"
                            >
                              Kunci Jawaban
                            </b-form-checkbox>
                            <b-form-radio-group
                              v-if="type == 'benar_salah'"
                              v-model="data.is_true_or_false"
                              :options="bsSoal"
                              name="radios-stacked"
                              stacked
                            />
                          </b-col>
                          <b-col md="10" v-if="type != 'pg'">
                            <label class="font-weight-bold mt-2"
                              >Upload file format: jpg, png, pdf, doc, mp3, mp4
                            </label>
                            <small class="text-info"> <b> (opsional)</b></small>
                            <b-form-file
                              :id="data.id"
                              class="mb-2"
                              v-model="data.opsi_file"
                              placeholder="Pilih file untuk di upload atau unggah..."
                              drop-placeholder="Drop file here..."
                              no-drop
                              accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                            />
                          </b-col>
                          <b-col md="12" v-if="type == 'pg'">
                            <label class="font-weight-bold mt-2"
                              >Upload file format: jpg, png, pdf, doc, mp3, mp4
                            </label>
                            <small class="text-info"> <b> (opsional)</b></small>
                            <b-form-file
                              :id="data.id"
                              class="mb-2"
                              v-model="data.opsi_file"
                              placeholder="Pilih file untuk di upload atau unggah..."
                              drop-placeholder="Drop file here..."
                              no-drop
                              accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                            />
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-tab>
                  </div>
                </b-tabs>
              </div>
              <div v-if="type == 'pauli'">
                <b-row v-for="(data, index) in item.dataOptions" :key="index">
                  <b-col md="4">
                    <b-form-group
                      :label="'Baris ' + (index + 1)"
                      :label-for="'h-baris-' + (index + 1)"
                    >
                      <b-form-input
                        :id="'h-baris-' + data.id"
                        placeholder="Isi Soal"
                        v-model="data.content"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      :label="'Jawaban Baris ' + (index + 1)"
                      :label-for="'h-baris-' + (index + 1)"
                    >
                      <b-form-input
                        :id="'h-baris-' + data.id"
                        placeholder="Isi Jawaban"
                        v-model="data.secret_key"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-button
                variant="success"
                @click.prevent="submit"
                class="w-full mt-1"
              >
                Simpan
              </b-button>
              <b-button
                variant="outline-success"
                @click.prevent="submit(true)"
                class="w-full mx-1 mt-1"
              >
                Simpan dan Buat Lagi
              </b-button>
              <b-button
                variant="outline-primary"
                @click.prevent="clearForm"
                class="w-full mx-1 mt-1"
              >
                Reset
              </b-button>
              <hr />
              <p>Note :</p>
              <p>
                Tanda bintang
                <small class="text-danger"> <i> (*)</i></small> berarti wajib
                diisi/pilih
              </p>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
// import 'vue-select/dist/vue-select.css';
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import id from "vee-validate/dist/locale/id.json";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BOverlay,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
const limitSoal = 5; // limit pembuatan soal
const mb = 1024;

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      locale: null,
      loading: false, // for animation loading data
      mapels: [],
      // default data
      dataSoal: [
        {
          paket_id: null,
          // judul: null,
          name: null,
          file: null,
          dataOptions: [],
          is_correct: null,
          pembahasan: null,
          bobot: 1,
          selectedOption: null,
        },
      ],

      opsi: null,
      active_soal_attachment: false,
      // judul: null,
      selectedOption: null,
      file: null,
      sum_option: null,
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      optmapel_id: [],
      optpaket_id: [],
      bobot: 0,
      name: null,
      type: "pg",
      mapels: [
        {
          value: "Matematika Kelas X IPA 1",
        },
      ],
      is_correct: 0,
      typesSoal: [
        {
          label: "Pilihan Ganda",
          value: "pg",
        },
        {
          label: "Multiple Choice",
          value: "multiple_choice",
        },
        // {
        //   label: "Pauli",
        //   value: "pauli",
        // },
        {
          label: "Pernyataan Benar Salah",
          value: "benar_salah",
        },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      opsisSoal: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      sum_options: [
        {
          id: null,
          name: "- Pilih -",
        },
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
      masterOptions: [
        {
          id: 0,
          label: "- Pilih -",
          value: 0,
        },
        {
          id: 1,
          label: "A",
          value: "A",
        },
        {
          id: 2,
          label: "B",
          value: "B",
        },
        {
          id: 3,
          label: "C",
          value: "C",
        },
        {
          id: 4,
          label: "D",
          value: "D",
        },
        {
          id: 5,
          label: "E",
          value: "E",
        },
      ],
      availableOptions: [
        {
          content: "",
        },
      ],
      dataOptions: [],
      activeAction: null,
    };
  },
  watch: {
    selectedOption(val) {
      let num = parseInt(val.name);
      this.dataSoal.forEach((item) => {
        item.dataOptions = [];
        for (let i = 0; i < val; i++) {
          let option = this.getOption(i);
          item.dataOptions.push({
            key: i,
            index: "Baris" + (i + 1),
            option,
            file: null,
            content: null,
            is_true_or_false: null,
            is_correct: null,
            secret_key: null,
          });
        }
      });
      // for (let i = 0; i < val; i++) {
      //     let option = this.getKunjaw(i)
      //     this.masterOptions.push({
      //       id:i,
      //       label:i,
      //       value:i,
      //       option,
      //     })
      //   }
    },
  },
  computed: {
    patternStyle() {
      return "style";
    },
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    clearForm() {
      // check jika sudah ada yg ngisi atau belm
      const thereInputedData = this.dataSoal.some((soal) => {
        return (
          !["", undefined, null].includes(soal.name) ||
          !["", undefined, null].includes(soal.content) ||
          !["", undefined, null].includes(soal.pembahasan) ||
          soal.dataOptions.length > 0
        );
      });

      if (thereInputedData) {
        // kasih warning akan terhapus inputannya
        const resetConfirmed = confirm(
          "Anda yakin? semua yang anda ketikan akan dihapus?"
        );
        if (resetConfirmed) {
          this.dataSoal = [
            {
              // judul: null,
              name: null,
              file: null,
              option: null,
              dataOptions: [],
              secretKey: null,
              bobot: 1,
            },
          ];
          this.type = null;
          this.mapel_id = null;

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } else {
        this.dataSoal = [
          {
            // judul: null,
            name: null,
            file: null,
            option: null,
            dataOptions: [],
            secretKey: null,
            bobot: 1,
          },
        ];
        this.type = null;
        this.mapel_id = null;

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    addAttachSoal(ind, e) {
      document.getElementById(`file_soal_${ind}`).click();
    },
    addAttachSoalOption(ind, e) {
      document.getElementById(ind).click();
    },
    addSoal() {
      if (this.dataSoal.length >= limitSoal) {
        this.$vs.notify({
          title: "Batas Pembuatan Soal Tercapai",
          text: "",
          variant: "warning",
          position: "top-right",
        });
        return false;
      }

      let data = {
        no: this.dataSoal.length + 1,
        // judul: null,
        name: null,
        file: null,
        option: null,
        dataOptions: [],
        // is_correct: null,
        bobot: 1,
      };

      if (this.selectedOption != null) {
        for (let i = 0; i < this.selectedOption; i++) {
          let option = this.getOption(i);
          data.dataOptions.push({
            key: i,
            option,
            file: null,
            content: null,
          });
        }
      }

      //
      this.dataSoal.push(data);
    },
    updateDataSoal(val) {
      let vm = this;
      this.$route.meta.breadcrumb[2].url =
        "/soal/daftarsoal?mp=" + this.$route.query.mp;
      val.forEach((item, index) => {
        // Change when user choose Jumlah Opsi
        if (item.selectedOption != null) {
          let numOpsi = parseInt(item.selectedOption.name);
          if (item.dataOptions.length > 0) {
            item.dataOptions = [];
          }

          if (item.dataOptions.length < 1) {
            for (let i = 0; i < numOpsi; i++) {
              let option = this.getOption(i);
              vm.dataSoal[index].dataOptions[i] = {
                key: i,
                option,
                file: null,
                content: null,
              };
            }
          }
        }
      });
    },
    getEditorOptions(id_file_input) {
      // get event link click in dynamic options
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
            handlers: {
              link: function (val) {
                document.getElementById(id_file_input).click();
              },
            },
          },
        },
      };
    },
    getOption(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },

    getKunjaw(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },
    removeOptions(ind) {
      if (this.dataOptions.length > 1) {
        this.dataOptions.splice(ind, 1);
      }
    },
    optionNull() {
      let hasOption = this.dataSoal.filter((soal) =>
          soal.dataOptions.find(
            (item) =>
              item.content == null || item.content == "" || item.option == null
          )
        ),
        hasNull = 0;

      this.dataSoal.forEach((soal) => {
        let find = soal.dataOptions.find(
          (item) =>
            item.content == null || item.content == "" || item.option == null
        );

        if (find) {
          hasNull++;
        }
      });

      return this.type == "pg" ? hasNull > 0 : false;
    },
    addOption() {
      // untuk menambah opsi
      this.dataOptions.push({
        option: null,
        content: null,
      });
    },
    fileToBase64(fileObj) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(fileObj);
      });
    },
    validateForm() {
      this.dataSoal.map((soal) => {
        soal.name == "" ||
          soal.name == null ||
          soal.bobot == "" ||
          soal.bobot == null ||
          soal.paket_id == "" ||
          soal.paket_id == null ||
          soal.content == "" ||
          soal.content == null ||
          soal.pembahasan == "" ||
          soal.pembahasan == null;
      });
      // Check if there question with empty soal || empty secret
      // let thereEmptyQuest = 0;
    },
    async submit(createAgainAction = false) {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          // create var for form data
          const fd = new FormData();
          if (this.type === "pg") {
            // validation option
            if (this.optionNull()) {
              this.$vs.notify({
                title: "Harap isi opsi yang masih kosong",
                text: "",
                iconPack: "feather",
                icon: "icon-alert-circle",
                variant: "warning",
                position: "top-right",
              });
              return false;
            }
          }

          this.dataSoal.map(async (soal, i) => {
            // validate content (html tag)
            // if there style atteribute, replace with style2
            let content = soal.content;
            content = content.replaceAll("style", "style2");

            // append soal to form data
            fd.append(`data[${i}][user_id]`, this.user.id);
            fd.append(`data[${i}][category_paket_id]`, soal.paket_id);
            fd.append(`data[${i}][name]`, soal.name);
            fd.append(`data[${i}][content]`, content);
            fd.append(`data[${i}][bobot]`, soal.bobot);
            fd.append(`data[${i}][pembahasan]`, soal.pembahasan);
            fd.append(`data[${i}][type]`, this.type);

            // if there file soal, append it
            if (soal.file != null) {
              fd.append(`data[${i}][file]`, soal.file);
            }

            // if pg, try to append option
            if (soal.dataOptions.length > 0 && this.type === "pg") {
              soal.dataOptions.map(async (option, i_opsi) => {
                // check if content has style attribute, change to style2
                let contentOption = option.content;
                contentOption = contentOption.replaceAll("style", "style2");
                const answerKey = this.is_correct == option.option ? 1 : 0;
                // const answerKey = soal.is_correct == option.option ? 1 : 0
                fd.append(
                  `data[${i}][options][${i_opsi}][option]`,
                  option.option
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][content]`,
                  contentOption
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][is_correct]`,
                  answerKey
                );
                // fd.append(`data[${i}][options][${i_opsi}][is_correct]`, answerKey)

                // check if there option file attached
                if (option.opsi_file !== null) {
                  fd.append(
                    `data[${i}][options][${i_opsi}][file]`,
                    option.opsi_file
                  );
                }
              });
            }
            if (
              soal.dataOptions.length > 0 &&
              this.type === "multiple_choice"
            ) {
              soal.dataOptions.map(async (option, i_opsi) => {
                // check if content has style attribute, change to style2
                let contentOption = option.content;
                contentOption = contentOption.replaceAll("style", "style2");
                // const answerKey = this.is_correct == option.option ? 1 : 0
                // const answerKey = soal.is_correct == option.option ? 1 : 0
                fd.append(
                  `data[${i}][options][${i_opsi}][option]`,
                  option.option
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][content]`,
                  contentOption
                );
                if (option.is_correct == 1) {
                  fd.append(
                    `data[${i}][options][${i_opsi}][is_correct]`,
                    option.is_correct
                  );
                }
                if (option.is_correct != 1) {
                  fd.append(`data[${i}][options][${i_opsi}][is_correct]`, 0);
                }

                // check if there option file attached
                if (option.opsi_file !== null) {
                  fd.append(
                    `data[${i}][options][${i_opsi}][file]`,
                    option.opsi_file
                  );
                }
              });
            }
            if (soal.dataOptions.length > 0 && this.type === "benar_salah") {
              soal.dataOptions.map(async (option, i_opsi) => {
                // check if content has style attribute, change to style2
                let contentOption = option.content;
                contentOption = contentOption.replaceAll("style", "style2");
                // const answerKey = this.is_correct == option.option ? 1 : 0
                // const answerKey = soal.is_correct == option.option ? 1 : 0
                fd.append(
                  `data[${i}][options][${i_opsi}][option]`,
                  option.option
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][content]`,
                  contentOption
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][is_true_or_false]`,
                  option.is_true_or_false
                );
                // check if there option file attached
                if (option.opsi_file !== null) {
                  fd.append(
                    `data[${i}][options][${i_opsi}][file]`,
                    option.opsi_file
                  );
                }
              });
            }
            if (soal.dataOptions.length > 0 && this.type === "pauli") {
              soal.dataOptions.map(async (option, i_opsi) => {
                // const answerKey = this.is_correct == option.option ? 1 : 0
                // const answerKey = soal.is_correct == option.option ? 1 : 0
                fd.append(
                  `data[${i}][options][${i_opsi}][option]`,
                  option.index
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][content]`,
                  option.content
                );
                fd.append(
                  `data[${i}][options][${i_opsi}][secret_key]`,
                  option.secret_key
                );
                // check if there option file attached
                if (option.opsi_file !== null) {
                  fd.append(
                    `data[${i}][options][${i_opsi}][file]`,
                    option.opsi_file
                  );
                }
              });
            }
          });

          try {
            this.loading = true;
            await this.$store.dispatch("banksoal/createUpdateDelete", fd);
            this.loading = false;
            this.activeAction = "tambah";
            if (createAgainAction != true) {
              this.$router.push("/master-admin/bank-soal");
            }
            this.displaySuccess({
              text: "Soal berhasil di" + this.activeAction,
            });
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
        if (!success) {
          this.pesanGagal();
        }
      });
    },
    checkQuery() {
      if (this.$route.query.s == undefined) {
        this.$router.push({ path: "/soal" });
      }
    },
    handleOptionFile(e, id) {
      // add file dynamic option to data
      let objFile = e.target.files[0];
      this.dataSoal[id.soal].dataOptions[id.opsi].file = objFile;

      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[id.soal].dataOptions[id.opsi].file =
          URL.createObjectURL(objFile);
      } else {
        this.dataSoal[id.soal].dataOptions[id.opsi].file = null;
      }
    },
    handleFile(ind, e) {
      // add soal file to data
      let objFile = e.target.files[0];
      this.dataSoal[ind].file = objFile;
      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[ind].preview_file = URL.createObjectURL(objFile);
      } else {
        this.dataSoal[ind].preview_file = null;
      }
    },
    unhandleFile(ind, e) {
      // remove soal file
      this.dataSoal[ind].file = null;
      this.dataSoal[ind].preview_file = null;
      e.target.value = null;
    },
    unhandleFileOption(e, id) {
      // remove file dynamic option
      this.dataSoal[id.soal].dataOptions[id.opsi].file = null;
      this.dataSoal[id.soal].dataOptions[id.opsi].preview_file = null;
      e.target.value = null;
    },
    resetHandleFile(ind, e) {
      this.$refs["file_" + ind].value = "";
    },
    resetHandleOptionFile(e, id_file_input) {
      this.$refs[id_file_input].value = "";
    },
    doFormatRupiah() {
      this.banksoalDataId.price = this.formatRupiah(this.banksoalDataId.price);
      this.banksoalDataId.max_quota = this.formatRupiah(
        this.banksoalDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.banksoalDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "AlertCircleIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getDataPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          // paket_id.unshift({ id: null, name: "- Pilih Kategori Paket -" });
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket_id = paket_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    switchLoc() {
      // switch the locale.
      this.locale = this.locale === "id" ? "id" : "id";
      localize(this.locale, id);

      // re-validate to re-generate the messages.
      // this.$refs.simpleRules.validate();
    },
  },
  mounted() {
    this.getDataPaket();
    if (this.$route.params.id != null) {
    }
    this.switchLoc();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
